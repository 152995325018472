
.studies-new-section {
    padding: 140px 0 30px 0;
    background: #F4FAFE;
    min-height: 1400px;

    .industry-new-content {
        padding-bottom: 40px;
        h2 {
            text-align: center;
            font-size: 40px;
            line-height: 150%;
            font-weight: 700;
            color: #040405;
            margin: 0;
        }
    }
    .studie-project-card {
        border-radius: 16px;
        background: #12123C;
        margin-bottom: 40px;
        height: 100%;
        display: flex;
        min-height: 540px;
        padding: 32px;
        padding-right: 0;
        .row {
            width: 100%;
            margin: 0;
        }
        .project-left-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .project-top-content {
                .project-top-logo {
                    margin-bottom: 37px;
                    max-width: 120px;
                    img {
                        width: 100%;
                    }
                }
                .project-heading-text {
                    h2 {
                        margin-bottom: 34px;
                        font-size: 28px;
                        line-height: 140%;
                        font-weight: 700;
                        color: #fff;
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 16px;
                        li {
                            padding-right: 22px;
                            list-style: none;
                            span {
                                font-size: 16px;
                                line-height: 150%;
                                font-weight: 500;
                                color: #fff;
                                padding-bottom: 4px;
                                display: block;
                                opacity: 0.56;
                            }
                            p {
                                font-size: 24px;
                                line-height: 150%;
                                font-weight: 700;
                                color: #fff;
                                margin-bottom: 0;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            .case-study-link {
                font-size: 16px;
                line-height: 150%;
                font-weight: 500;
                color: #fff;
                opacity: 0.56;
                margin: 0;
                transition: 0.3s;
                span {
                    margin-left: -10px;
                    opacity: 0;
                    transition: 0.3s;
                    img {
                        width: 24px;
                    }
                }
                &:hover {
                    opacity: 1;
                    span {
                        margin-left: 5px;
                        opacity: 1;
                    }
                }
            }
        }
        .project-right-content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
            }
        }
    }
    .studie-project-card-yellow {
        background-color: #FBBC2E;
    }
    .studie-project-card-blue {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #365DA2 0%, #274E94 100%);
    }
    .studie-project-card-green {
        background-color: #25938C;
    }
}


.resources-nu-card {
    // border: 1px solid var(--primary-border-light);
    // background: var(--resource-card-bg);
    border-radius: 16px;
    padding: 0;
    min-height: unset;
    transition: all .3s ease;
    // background-color: var(--section-secondary);
    word-break: break-all;

    h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #fff !important;
    }

    h3 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #fff !important;
    }

    // &:hover {
    //     border-color: var(--resorce-card-border);
    //     background-color: var(--section-secondary);
    // }
}

.stack-deployed-div {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;

    li {
        display: inline-block;
        margin: 4px 12px 4px 0;
        padding: 0 !important;

        &:first-child {
            // margin-left: px;
        }

        .stack-deployed-img {
            display: flex;
            background: #fff;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            border: 2px solid rgba(0, 134, 230, 0.20);
            overflow: hidden;
            width: 60px;
            height: 60px;
            padding: 10px;
            transition: all .3s ease;

            img {
                width: 32px;
                height: auto;
            }

            // &:hover {
            //     border-color: var(--resorce-card-border);
            // }
        }
    }
}

.case-tool-tip {
	padding-left: 20px;
	padding-right: 20px;

	p {
		font-size: 10px;
		line-height: 14px;
		margin-bottom: 2px;
		color: rgba(255, 255, 255, 0.6);
	}

	span {
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: #fff;
	}
}


@media only screen and (max-width: 1400px) and (min-width: 1025px) {
    .container {
        max-width: 1000px !important;
    }
    .studies-new-section {
        min-height: 1030px;
        padding-bottom: 10px !important ;
    }
    .studies-new-section .studie-project-card {
        margin-bottom: 30px;
        min-height: 420px;
    }
    .studies-new-section .studie-project-card {
        padding: 24px 20px;
    }
    .studies-new-section .studie-project-card .project-left-content .project-top-content .project-heading-text h2 {
        font-size: 22px !important;
        line-height: 30px !important;
    }
    .studies-new-section
        .studie-project-card
        .project-left-content
        .project-top-content
        .project-heading-text
        ul
        li
        span {
        font-size: 12px;
        line-height: 18px;
    }
    .studies-new-section .studie-project-card .project-left-content .project-top-content .project-heading-text ul li p {
        font-size: 18px;
        line-height: 26px;
    }
    .studies-new-section .studie-project-card .project-left-content .case-study-link {
        font-size: 14px;
        line-height: 20px;
    }
    .studies-new-section .industry-new-content {
        padding-bottom: 32px;
    }
    .studies-new-section .industry-new-content h2 {
        font-size: 32px;
    }
}

@media (max-width: 1024px) {
    .studies-new-section {
        padding: 80px 0 10px 0;
        min-height: auto;
    }
    .studies-new-section .studie-project-card {
        margin-bottom: 30px;
        min-height: 420px;
    }
    .studies-new-section .industry-new-content {
        padding-bottom: 32px;
    }
    .studies-new-section .industry-new-content h2 {
        font-size: 32px;
    }
    .studies-new-section .studie-project-card {
        padding: 24px 20px;
        padding-right: 0;
    }

    .resources-nu-card {
		padding: 0;
		min-height: unset;
	}

	 .resources-nu-card h4 {
		font-size: 14px;
		line-height: 20px;
	}

	 .resources-nu-card h3 {
		font-size: 18px;
		line-height: 24px;
	}
}

@media (max-width: 992px) {
    .studies-new-section {
        padding: 40px 0 14px 0;
        .industry-new-content {
            h2 {
                font-size: 24px;
            }
            padding-bottom: 20px;
        }
        .studie-project-card {
            .project-left-content {
                .project-top-content {
                    .project-heading-text {
                        h2 {
                            font-size: 22px;
                            margin-bottom: 24px;
                        }
                        ul {
                            li {
                                span {
                                    font-size: 14px;
                                }
                                p {
                                    font-size: 18px;
                                }
                                padding-right: 12px;
                            }
                        }
                    }
                }
                padding: 0 0 40px 0;
                .case-study-link {
                    font-size: 14px;
                }
            }
        }
    }

    .resources-nu-card {

        h4 {
            font-size: 14px;
            line-height: 22px;
        }

        h3 {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .stack-deployed-div {
        li {
            margin: 4px 5px 0px;

            .stack-deployed-img {
                width: 45px;
                height: 45px;
                border-radius: 10px;

                img {
                    width: 23px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .studies-new-section {
        padding: 60px 0 10px 0;
    }
    .studies-new-section .studie-project-card .project-left-content .project-top-content .project-top-logo {
        margin-bottom: 24px;
    }

    .studies-new-section .studie-project-card .project-left-content .project-top-content .project-top-logo {
        max-width: 90px;
    }
    .studies-new-section .studie-project-card {
        margin-bottom: 26px;
    }
    .studies-new-section .studie-project-card .project-left-content .project-top-content .project-heading-text ul {
        margin-bottom: 20px;
    }
    .studies-new-section .studie-project-card .project-left-content .case-study-link {
        opacity: 1;
    }
    .studies-new-section .studie-project-card .project-left-content .case-study-link span {
        opacity: 1;
        margin-left: 10px;
    }
    .studies-new-section .studie-project-card .project-left-content .case-study-link span img {
        width: 16px;
    }

    // new

    
    .resources-nu-card {
        min-height: unset;
        height: 100%;

        h3 {
            font-size: 16px;
            line-height: 26px;
        }
    }

    .stack-deployed-div {
        li {
            margin: 4px 5px 0px;

            .stack-deployed-img {
                width: 35px;
                height: 35px;
                border-radius: 8px;

                img {
                    width: 20px;
                }
            }
        }
    }
}


@media (max-width:575px) {

    .resources-nu-card {        

        h3 {
            font-size: 16px;
            line-height: 19px;
        }

        h4 {
            font-size: 14px;
            line-height: 21px;
        }
    }

}